<template>
  <div class="cardForm">
    <div class="row">
      <div class="col-12">
        <div class="form-group">
          <label for="card-number-element">Credit Card Number</label>
          <div id="card-number-element" class="form-control"></div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="card-expiry-element">Expiry Month/Year</label>
          <div id="card-expiry-element" class="form-control"></div>
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label for="card-cvc-element">CVC Code</label>
          <div id="card-cvc-element" class="form-control"></div>
        </div>
      </div>
      <div v-if="error" class="invalid-feedback col-12">
        {{ error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {},

  data() {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPE_KEY,
      clientSecret: null,
      cardElement: null,
      paymentMethodId: null,
      error: null,
    }
  },

  mounted() {
    this.includeStripe()
  },

  methods: {
    async getClientSecret() {
      await this.$store.dispatch('paymentMethod/getSecret').then((res) => {
        this.clientSecret = res.data
      })
    },

    includeStripe() {
      if (document.getElementById('stripe-js')) {
        this.configureStripe()
        return
      }

      let documentTag = document
      let object = documentTag.createElement('script')
      let scriptTag = documentTag.getElementsByTagName('script')[0]

      object.id = 'stripe-js'
      object.src = '//js.stripe.com/v3/'
      object.addEventListener(
        'load',
        (e) => {
          this.configureStripe()
        },
        false
      )
      scriptTag.parentNode.insertBefore(object, scriptTag)
    },

    configureStripe() {
      this.stripe = window.Stripe(this.stripeAPIToken)
      const elements = this.stripe.elements()

      this.cardElement = elements.create('cardNumber', {})
      this.cardElement.mount('#card-number-element')

      this.cardElement.on('change', (event) => {
        this.error = null

        if (!event.complete) {
          this.error = event.error ? event.error.message : null
          this.paymentMethodId = null
        }
      })

      let cardExpiryElement = elements.create('cardExpiry', {})
      cardExpiryElement.mount('#card-expiry-element')

      cardExpiryElement.on('change', (event) => {
        this.error = null

        if (!event.complete) {
          this.error = event.error ? event.error.message : null
          this.paymentMethodId = null
        }
      })

      let cardCvcElement = elements.create('cardCvc', {})
      cardCvcElement.mount('#card-cvc-element')

      cardCvcElement.on('change', (event) => {
        this.error = null

        if (!event.complete) {
          this.error = event.error ? event.error.message : null
          this.paymentMethodId = null
        }
      })
    },

    validate() {
      return new Promise(async (resolve, reject) => {
        // Card error
        if (this.error) return reject(this.error)
        // Card already validation
        if (this.paymentMethodId) return resolve(this.paymentMethodId)

        if (!this.paymentMethodId || !this.clientSecret) {
          await this.getClientSecret()
        }

        const { setupIntent, error } = await this.stripe.confirmCardSetup(
          this.clientSecret,
          {
            payment_method: {
              card: this.cardElement,
            },
          }
        )

        if (error) {
          this.error = error.message
          reject(error.message)
        } else {
          this.paymentMethodId = setupIntent.payment_method
          resolve(setupIntent.payment_method)
        }
      })
    },
  },
}
</script>

<style scoped>
.invalid-feedback {
  display: block;
  margin-top: 0;
  font-size: 80%;
  color: #ff5c75;
}
</style>
